// import { Link } from "gatsby"
import React from "react"

import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
    <footer>
        <Container>
            <Row>
                <Col md={1}></Col>
                <Col md={4}>
                    <h2>Howard Wire Cloth Company</h2>
                    <p>28976 Hopkins Street, Suite A,<br />
                       Hayward, CA 94545</p>
                </Col>
                <Col md={2}>
                    <h2>Service Area</h2>
                    <p>Northern California</p>
                </Col>
                <Col md={2}>
                    <h2>E-mail Us</h2>
                    <p><a href="mailto:sales@howardwire.com">sales@howardwire.com</a></p>
                </Col>
                <Col md={2}>
                    <h2>Give us a call</h2>
                    <p>(510) 887-8787</p>
                </Col>
                <Col md={1}></Col>
            </Row>
        </Container>
        <Container className="text-center">
            <p>Custom Wire Mesh and Wire Cloth Manufacturers California Since 1938</p>
            <p>© {new Date().getFullYear()} | Site designed by <a href="https://www.nautilusdesigns.com">Nautilus Designs</a></p>
        </Container>
    </footer>
)

export default Footer
