import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import logo from "../images/howard-wire-cloth.png"

// import navDropdownExample from "./NavDropdownExample"


const NavigationDropDownLink = (props) => (
  <Link to={props.linkTo} className="dropdown-item" activeClassName="active">{props.title}</Link>
)


export default () => (


  
  <span>
    <Container>
      <div className="topHeaderInfo">
        <p>(510) 887-8787 | <a href="mailto:sales@howardwire.com">sales@howardwire.com</a></p>
      </div>
    </Container>
  <header>    
    <Container>
      <Navbar expand="md">
      <Navbar.Brand href="/">
      <img
        alt="Howard Wire Logo"
        src={logo}
        className="img-fluid"
      />
    </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="justify-content-center" />
        <Navbar.Collapse className="justify-content-end text-center" id="responsive-navbar-nav">
          <Nav className="justify-content-end" as="ul">
            <li>
              <Link to="/about/" className="nav-link" activeClassName="active">About</Link>
            </li>
            
            <NavDropdown title="Products" id="basic-nav-dropdown">
              <NavigationDropDownLink  
                title="Custom Options"
                linkTo="/custom-options/"
              />
              <NavigationDropDownLink  
                title="Galvanized Hardware Cloth and Others"
                linkTo="/galvanized-hardware-cloth-and-others/"
              />
              <NavigationDropDownLink  
                title="Expanded Metal"
                linkTo="/expanded-metal/"
              />
              <NavigationDropDownLink  
                title="Perforated Metal"
                linkTo="/perforated-metal/"
              />
              <NavigationDropDownLink  
                title="Welded Wire Mesh Rolls"
                linkTo="/welded-wire-mesh-rolls/"
              />
              <NavigationDropDownLink  
                title="Welded Wire Panels"
                linkTo="/welded-wire-panels/"
              />
              <NavigationDropDownLink  
                title="Woven Wire Cloth"
                linkTo="/woven-wire-cloth/"
              />
              
            </NavDropdown>
            <li>
              <Link to="/contact/" className="nav-link" activeClassName="active">Contact Us</Link>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
  </span>
)

